<template>
  <div class="pb-2">
    <Candidates
      :candidatesInfo="candidates"
      :selectedCandidate="candidateDetails"
      @getCandidateDetails="getCandidateDetails"
      :selectedTabIndex="6"
      @actionModalEvent="actionModalEvent"
    />
    <!-- @actionModalEvent="actionModalEvent"
      :actionModal="actionModal" -->
    
    <CModal
      title="Candidate Offer - Documents"
      centered:true
      color="primary"
      :show.sync="offerModel.isShowPopup"
      v-if="offerModel.isShowPopup"
      size="lg"
    >
      <CRow class="row mb-3">
        <div class="p-3" style="width:100%">
          <CTabs variant="tabs" :active-tab.sync="currentTab" @update:activeTab="onTabChange">
            <CTab :title="`Job`">
              <div v-if="jobDocuments.length" class="multi-records">
                <div
                  class="top-border d-flex"
                  style="justify-content: space-between"
                  v-for="(data, index) in jobDocuments"
                  :key="index"
                >
                  <div class="ml-2 mt-1 record" style="min-width:80%">
                    <CRow
                      class="d-flex"
                      style="justify-content: space-around; margin-left: 2px"
                    >
                      <div class="table col col-4">
                        <p class="head">Document Type</p>
                        <p class="data">{{ data.label }}</p>
                      </div>
                      <div class="table col col-4">
                        <p class="head">Document Name</p>
                        <p class="data">{{ data.name }}</p>
                      </div>
                      <div class="table col col-4">
                        <p class="head">Uploaded Date</p>
                        <p class="data">{{ data.updatedDate }}</p>
                      </div>
                      <!-- <div class="text-justify table col col-12">
                        <p class="head">Description</p>
                        <a class="hand-icon btn pr-3 p-0" @click="editInfo(data)">
                          <i class="fas fa-pencil-alt"></i>
                        </a>
                        {{ data.comments ? data.comments : "--" }}
                      </div> -->
                    </CRow>
                  </div>

                  <div
                    class="widget-content-actions d-flex flex-column justify-content-center"
                    style="margin-right: 5px;"
                  >
                    <button
                      type="button"
                      :disabled="disabledButton.includes(index)"
                      class="btn icon hand-icon mb-1"
                      v-if="data && data.link"
                      @click="fileDownload(data, index)"
                    >
                      <i class="icon fas fa-download"></i>
                    </button>

                    <button
                      type="button"
                      class="btn icon hand-icon mb-1"
                      v-if="data && data.link"
                      @click="preview(data)"
                    >
                      <i class="fas fa-eye"></i>
                    </button>

                    <!-- <button
                      class="btn icon hand-icon"
                      v-if="data && data.link"
                      @click="deleteDocuments(data)"
                    >
                      <i class="fas fa-trash"></i>
                    </button> -->
                  </div>
                </div>
              </div>
            </CTab>
            <CTab :title="`Candidate`">
              <CCardGroup class="has-loading-overlay" v-if="currentTab == 1">
                <CCard class="p-2">
                  <CCardBody>
                    <ValidationObserver v-slot="{ handleSubmit }">
                      <form @submit.prevent="handleSubmit(onSubmit)">
                        <CRow class="row mb-3">
                          <label class="col-lg-3 col-md-12">Document Type</label>
                          <div class="col-lg-4 col-md-6 col-sm-12">
                            <Select
                              name="document_type"
                              :value="uploadData.document_type || `Offer Letter`"
                              @input="handleChangeSelect"
                              :options="
                                options && options['document_type']
                                  ? options['document_type']
                                  : []
                              "
                              :taggable="false"
                              :multiple="false"
                              :clearable="false"
                            />
                          </div>
                        </CRow>
                        <CRow class="row mb-3">
                          <label class="col-lg-3 col-md-12">Upload Document</label>
                          <div class="col-lg-4 col-md-6 col-sm-12">
                            <input
                              name="upload-document"
                              type="file"
                              ref="fileref"
                              :accept="arraytoComma(fileType)"
                              @change="selectedFile"
                            />
                          </div>
                          <div v-if="isImageSelected">
                            <uploadDocument
                              :documentType="uploadData.document_type"
                              :isProfilePic="false"
                              :fileData="fileData"
                              @closeModal="closeModal"
                              v-on="$listeners"
                              @upload="upload"
                            />
                          </div>
                          <!-- <div v-if="cropperModal.isShowPopup">
                            <CropperModal
                              :isShowPopup="cropperModal.isShowPopup"
                              :fileData="cropperModal.fileData"
                              modalColor="primary"
                              :buttonLabel="cropperModal.buttonLabel"
                              @modalCallBack="cropperModalCallBack"
                              :aspectRatio="2"
                            />
                          </div> -->
                        </CRow>
                      </form>
                    </ValidationObserver>
                  </CCardBody>
                  <CRow
                    class="ml-2 mr-2 d-flex align-items-center documentScrolling document-table"
                    v-if="candidateDocument"
                  >
                    <table class="table">
                      <thead>
                        <tr>
                          <th class="w-25">Document Type</th>
                          <th>Document Name</th>
                          <th>Uploaded Date</th>
                          <th>Download</th>
                          <th>Preview</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(data, index) in candidateDocument" :key="index" >
                          <td :class="{ 'w-25': true, [data.label]: true }">
                            {{ data.label }}
                          </td>
                          <td class="candidate-file-name">{{ data.name }}</td>
                          <td>{{ data.updatedDate }}</td>
                          <td>
                            <button
                              :disabled="disabledButton.includes(index)"
                              class="hand-icon action-button"
                              v-if="data && data.link"
                              @click="fileDownload(data, index)"
                            >
                              <i class="icon fas fa-download ml-3"></i>
                            </button>
                          </td>
                          <td>
                            <a class="hand-icon" v-if="data && data.link"  @click="preview(data)">
                                <i class="icon fas fa-eye ml-3"></i>
                            </a>
                          </td>
                          <td>
                            <button 
                            class="hand-icon action-button" v-if="data && data.link" @click="deleteDocuments(data)">
                              <i class="icon fas fa-trash ml-3"></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </CRow>
                </CCard>
              </CCardGroup>
            </CTab>
          </CTabs>
        </div>
      </CRow>
      <template #footer-wrapper>
        <footer class="modal-footer">
          <button @click="onCancelClicked" class="btn btn-secondary">
            Cancel
          </button>
        </footer>
      </template>
    </CModal>

    <PreviewModal
      :modalTitle="confirmationModal.modalTitle"
      :modalColor="confirmationModal.modalColor"
      :isShowPopup="confirmationModal.isShowPopup"
      :buttons="confirmationModal.buttons"
      :modalCallBack="modalCallBack"
      :isPreview="confirmationModal.isPreview"
      :iframe="iframe"
      :size="confirmationModal.size"
      :closeOnBackdrop="confirmationModal.closeOnBackdrop"
    />
      
      <Modal 
        :modalTitle="deleteConfirmModal.modalTitle" 
        :modalColor="deleteConfirmModal.modalColor" 
        :modalContent="deleteConfirmModal.modalContent" 
        :isShowPopup="deleteConfirmModal.isShowPopup" 
        :buttons="deleteConfirmModal.buttons"
        :modalCallBack="deletemodalCallBack"
        :closeOnBackdrop="false"
     />
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import Candidates from "@/containers/JobsDetailedView/Candidate/CandidateMatch";
import PreviewModal from "@/components/reusable/PreviewModal";
import pdf from "vue-pdf";
import moment from "moment";

import UploadDocument from "@/containers/CandidateProfile/UploadDocument";
import Select from "@/components/reusable/Fields/Select.vue";
import CropperModal from "@/components/reusable/CropperModal";
import Modal from "@/components/reusable/Modal";

export default {
  components: {
    Candidates,
    PreviewModal,
    UploadDocument,
    Select,
    CropperModal,
    Modal,
  },
  data() {
    return {
      candidateDetails: null,
      offerModel: {
        isShowPopup: false,
        data: null,
      },
      currentTab: null,
      disabledButton: [],
      iframe: {
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: false,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
      confirmationModal: {
        modalColor: "primary",
        modalTitle: "Document Preview",
        isShowPopup: false,
        buttons: ["Ok"],
        isPreview: false,
        size: "lg",
        closeOnBackdrop: false,
      },
      uploadData: {},
      payload: {},
      isFileError: false,
      isFileTpeValid: false,
      FileTypeError: null,
      isImageSelected: false,
      fileData: {},
      cropperModal: {
        isShowPopup: false,
        fileData: null,
        buttonLabel: "Apply",
      },
      candidate_document_id: null,
      deleteConfirmModal:{
        modalColor :"danger",
        modalTitle :"NOTIFICATION",
        modalContent :"",
        isShowPopup :false,
        buttons :["Cancel","Confirm"],
      },
    };
  },
  computed: {
    ...mapGetters([
      "getCustomerId",
      "getCandidateListForJobID",
      "noMoreCandidates",
      "getJobDocuments",
      "documentType",
      "uploaded",
      "getUploadedDocument",
      "candidateProfile"
    ]),
    options() {
      return {
        document_type:
          this.documentType
            .filter(({ code }) => [23, 24].includes(code))
            .map((data) => data) || [],
            // 23 - Offer Letter, 24 - Employment Contract 
      };
    },
    candidates() {
      if (
        this.getCandidateListForJobID &&
        this.getCandidateListForJobID.length
      ) {
        if (this.candidateDetails === null) {
          this.candidateDetails = this.getCandidateListForJobID[0];
        }
        this.getCandidateListForJobID.forEach(element => {
          element.actions = [{name: "Offer"}]
        });
        return this.getCandidateListForJobID;
      }
      this.candidateDetails = null;
      return [];
    },
    jobDocuments() {
      return (
        this.getJobDocuments?.length &&
        this.getJobDocuments.map(
          ({
            document_type_id,
            link_to_doc,
            document_name,
            documentlibrary,
            document_type,
            created_on,
            document_ext,
            candidate_document_id,
            comments,
          }) => {
            return {
              document_type_id: document_type_id,
              link: link_to_doc,
              label: documentlibrary!=null ? documentlibrary.document_name : document_type.comments,
              name: document_name,
              updatedDate: moment(created_on).format("DD-MM-YYYY"),
              document_ext: document_ext?.document_ext,
              document_id: candidate_document_id,
              comments: comments,
            };
          }
        )
      );
    },
    candidateDocument() {
      return (
        this.getUploadedDocument &&
        this.getUploadedDocument.length &&
        this.getUploadedDocument
          .filter((docs) => {
            return [23, 24].includes(docs.document_type_id) && [this.offerModel.data?.job_id].includes(docs.job_id)
          })
          .map(
            ({
              document_type_id,
              link_to_doc,
              document_name,
              document_type,
              created_on,
              document_ext,
              candidate_document_id
            }) => {
              return {
                link: link_to_doc,
                label: document_type.comments,
                name: document_name,
                updatedDate: moment(created_on).format("DD-MM-YYYY"),
                document_ext:document_ext?.document_ext,
                document_id:candidate_document_id,
              };
            }
          )
      );
    },
    fileType(){
      if(this.documentType.length){
      if(this.uploadData.document_type&&this.uploadData.document_type.code){
        let fileext= this.documentType.filter(({code}) => code === this.uploadData.document_type.code).map(d=>d.allowed_extensions)[0];
        fileext=fileext.map(d=>d.toLowerCase());
        return fileext;
      }else{
        let fileext= this.documentType.filter(({code}) => code === 23).map(d=>d.allowed_extensions)[0];
        fileext=fileext.map(d=>d.toLowerCase());
        return fileext;
      }
      }
      return []
    }
  },
  methods: {
    ...mapActions([
      "fetchCandidateListForJobIDNew",
      "getCandidateDetails",
      "commitCandidateInfo",
      "fetchJobDocumentsByJobID",
      "downloadDocument",
      "initFetchOptions",
      "showToast",
      "uploadPhoto",
      "uploadResume",
      "fetchUploadedDocument",
      "deleteDocument"
    ]),
    getCandidateDetails(candidateDetails) {
      if (candidateDetails) {
        const { candidate, sub_status } = candidateDetails;
        this.candidateDetails = candidateDetails;
        let appendAction;
        appendAction = this.commitCandidateInfo(candidate);
        Promise.all([appendAction]).then((res) => {
        });
      }
      setTimeout(() => {
        document.getElementById("candidateDetails").scrollIntoView();
      }, 100);
    },
    onBodyScroll(e) {
      if (this.noMoreCandidates) return;
      if (
        e.target.clientHeight + e.target.scrollTop >=
        e.target.scrollHeight - 1
      ) {
        this.fetchCandidateListForJobIDNew({
          pagination: true,
          displayStatusSubStatus: [
            { display_status: 58, display_sub_status: 59 || 60 }
          ],
        });
      }
    },
    actionModalEvent(payload) {
      this.offerModel.data = payload?.candidateDetails || null;
      this.offerModel.isShowPopup = true;
      this.currentTab = 1;
      this.fetchUploadedDocument();
      let job_id  = payload?.candidateDetails?.job_id || null
      if (job_id) this.fetchJobDocumentsByJobID(job_id)
    },
    onCancelClicked() {
      this.payload = {};
      this.offerModel.isShowPopup = false;
    },
    onTabChange(tabIndex) {
      // if (tabIndex == 0) {
      //   let job_id  = this.offerModel.data?.job_id
      //   console.log(job_id);
      //   this.fetchJobDocumentsByJobID(job_id)
      // }
    },
    fetchOfferCandidates() {
      // this.$store.commit("SET_CANDIDATE_LIST_FOR_JOB_ID", []);
      let appendAction = [];
      let payload = {
        pagination: false,
        displayStatusSubStatus: [{ display_status: 58, display_sub_status: 59 }],
      };
      // appendAction = [this.fetchCandidateListForJobIDNew(payload)];
      this.fetchCandidateListForJobIDNew(payload).then((res) => {
        const ele = document.getElementById("c-body-app");
        if (ele) {
          ele.addEventListener("scroll", this.onBodyScroll);
        }
      })
      // Promise.all(appendAction).then(res => {
      //   console.log('Response');
      // });
    },
    handleChangeSelect(name, value) {
      Vue.set(this.uploadData, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null,
      };
    },
    selectedFile(event) {
      this.isFileError = false;
      this.isFileTpeValid = false;
      const size = event.target.files[0].size;
      if (Math.round(size / (1024 * 1024)) <= 2) {
        const file = event.target.files[0];
        const fileExt=this.getExtension(file.name);
        const fileTypeString=this.fileType
        if(fileTypeString.includes(fileExt)){
        if (file.type.startsWith("image/")) {
          // this.cropperModal.fileData = file;
          // this.cropperModal.isShowPopup = true;
          this.isImageSelected = true;
          this.fileData = file;
        } else {
          this.handleFileUploads(file);
        }
        }else{
          this.isFileTpeValid=true;
          this.FileTypeError=this.arraytoComma(fileTypeString);
          this.$refs.fileref.value = "";
          this.showToaster();
        }
      } else {
        this.$refs.fileref.value = "";
        this.isFileError = true;
        this.showToaster();
      }
    },
    handleFileUploads(event) {
      this.payload = {
        ...this.payload,
        name: ["file"],
        value: event,
        isPhotoUpload: false,
        job_id: this.offerModel.data?.job_id
      };
      this.upload(this.payload);
    },
    cropperModalCallBack(action, data) {
      if (action === false) {
        this.cropperModal.isShowPopup = false;
        this.cropperModal.fileData = null;
        this.$refs.fileref.value = "";
      } else {
        let { fileBlob, fileName } = data;
        this.payload = { 
          ...this.payload,
          isPhotoUpload: true,
          name: ["file"],
          value: fileBlob,
          document_name: fileName
        };
        this.cropperModal.isShowPopup = false;
        this.cropperModal.fileData = null;
        // this.$emit("imgUpdated", true);
        this.upload(this.payload);
      }
    },
    upload(payload) {
      const document_type = this.payload.document_type || 23;
      const { customer_uid, candidate_uid, user_id } = this.offerModel.data.candidate;
      if (payload && payload.name) {
        if (payload.isPhotoUpload) {
          this.uploadPhoto({
            customer_uid,
            candidate_uid,
            user_id,
            [payload.name]: payload.value,
            document_type,
            ["fileName"]: payload.document_name,
            job_id: this.offerModel.data?.job_id
          });
        } else {
          this.uploadResume({
            customer_uid,
            candidate_uid,
            user_id,
            [payload.name]: payload.value,
            document_type,
            job_id: this.offerModel.data?.job_id
          });
        }
      }
    },
    fileDownload(data, index) {
      this.disabledButton.push(index);
      let { link, name: document_name } = data;
      let url = link.replace("/api/v1/", "");
      this.downloadDocument({ url, document_name }).finally(() => {
        this.disabledButton.shift();
      });
      return false;
    },
    preview(data) {
      if (
        data.document_ext === "PNG" ||
        data.document_ext === "JPG" ||
        data.document_ext === "JPEG" ||
        data.document_ext === "GIF"
      ) {
        this.iframe.loaded = false;
        this.iframe.ispdf = false;
        this.iframe.isImg = true;
         this.iframe.src = "https://" + window.location.host + data.link;
       // this.iframe.src = "http://localhost" + data.link;
        this.confirmationModal.isPreview = true;
        this.confirmationModal.isShowPopup = true;
      } else if (data.document_ext === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
         this.iframe.pdf.src = pdf.createLoadingTask(
          "https://" + window.location.host + data.link
         );
        //this.iframe.pdf.src = pdf.createLoadingTask(
         // "http://localhost" + data.link
       // );
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.confirmationModal.isPreview = true;
        this.confirmationModal.isShowPopup = true;
      } else if (
        data.document_ext === "TXT" ||
        data.document_ext === "EPS" ||
        data.document_ext === "TIF" ||
        data.document_ext === "TIFF" ||
        data.document_ext === "RAW"
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message: "The selected file type is not available for Preview.",
        });
        this.iframe.src = "";
        this.iframe.loaded = false;
        this.iframe.isImg = false;
        this.iframe.pdf.ispdf = false;
        this.confirmationModal.isPreview = false;
        this.confirmationModal.isShowPopup = false;
      } else {
        this.iframe.isImg = false;
        this.iframe.ispdf = false;
        this.iframe.loaded = true;
         this.iframe.src =
           "https://view.officeapps.live.com/op/embed.aspx?src=https://" +
           window.location.host +
           data.link +
          "";
     /*   this.iframe.src =
          "https://view.officeapps.live.com/op/embed.aspx?src=http://localhost" +
          data.link +
          "";*/
        this.confirmationModal.isPreview = true;
        this.confirmationModal.isShowPopup = true;
      }
    },//----------------------------------------------------------------
    modalCallBack(action) {
      this.confirmationModal.isPreview = false;
      this.confirmationModal.isShowPopup = false;
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
    },
    closeModal() {
      this.$refs.fileref.value = "";
      this.uploadData = {};
      this.fileData = {};
      this.isImageSelected = false;
    },
    getExtension(filename) {
      let parts = filename.split('.');
      return parts[parts.length - 1];
    },
    arraytoComma(d){
      return d?.map(v=>"."+v).join(", ");
    },
    showToaster(){
      setTimeout(()=>{
        this.isFileError = false;
        this.isFileTpeValid = false;
      },5000)
    },
    deleteDocuments(data){
      this.deleteConfirmModal.modalContent = `Do you want to delete this document - ${data.name} ?`
      this.candidate_document_id = data.document_id
      this.deleteConfirmModal.isShowPopup = true
    },
    deletemodalCallBack(action){
      if(action === "Confirm"){
        this.deleteDocument(this.candidate_document_id);
      }
      this.candidate_document_id = "";
      this.deleteConfirmModal.isShowPopup = false;
    }
  },
  mounted() {
    this.fetchOfferCandidates();
    this.initFetchOptions();
  },
  beforeDestroy() {
    const ele = document.getElementById("c-body-app");
    if (ele) {
      ele.removeEventListener("scroll", this.onBodyScroll);
    }
  },
  watch: {
    uploaded() {
      if (this.uploaded) {
        this.$refs.fileref.value = "";
        this.uploadData = {};
        this.fetchUploadedDocument();
        this.fileData = {};
        this.isImageSelected = false;
      }
    },
  },
};
</script>


<style lang="scss" scoped>
  .hand-icon {
    cursor: pointer;
  }

  .action-button {
    width: 14px;
    height: 14px;
    border: 0;
    background: transparent;
    padding: 0;
    outline: none;
  }
</style>