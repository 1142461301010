<template>
  <div class="pb-2">
    <div class="document">
      <div v-if="isJobSupplierFromAccessToken && !isCandidateInRecruitmentProcess">
        <div
          class="d-flex align-items-center documentScrolling document-table border"
        >
          <table class="table">
            <thead>
              <tr>
                <th class="nowrap w-25">Type</th>
                <th class="nowrap">Description</th>
                <th class="nowrap">Name</th>
                <th class="nowrap">Date Uploaded</th>
                <th class="nowrap">Preview</th>
              </tr>
            </thead>
            <tbody class="text-black">
              <tr v-for="data in agencyCVDocument" :key="data.document_id">
                <td>{{ data.lable || "--" }}</td>
                <td class="text-justify">
                  {{ data.comments ? data.comments : "--" }}
                </td>
                <td class="candidate-file-name">{{ data.name || "--" }}</td>
                <td>{{ data.updatedDate }}</td>
                <td>
                  <a
                    class="hand-icon"
                    v-if="data && data.link"
                    @click="preview(data)"
                  >
                    <i class="fas fa-eye ml-3"></i>
                  </a>
                </td>
              </tr>
              <tr v-if="!agencyCVDocument.length">
                <td colspan="6">
                  <h6 class="text-center text-gray">
                    No Documents to Preview
                  </h6>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-else>
        <div
          class="d-flex align-items-center documentScrolling document-table border"
        >
          <table class="table">
            <thead>
              <tr>
                <th class="nowrap w-25">Type</th>
                <th class="nowrap">Description</th>
                <th class="nowrap">Name</th>
                <th class="nowrap">Date Uploaded</th>
                <th class="nowrap">Preview</th>
              </tr>
            </thead>
            <tbody class="text-black">
              <tr v-for="data in resumeDocument" :key="data.document_id">
                <td>{{ data.lable || "--" }}</td>
                <td class="text-justify">
                  {{ data.comments ? data.comments : "--" }}
                </td>
                <td class="candidate-file-name">{{ data.name || "--" }}</td>
                <td>{{ data.updatedDate }}</td>
                <td>
                  <a
                    class="hand-icon"
                    v-if="data && data.link"
                    @click="preview(data)"
                  >
                    <i class="fas fa-eye ml-3"></i>
                  </a>
                </td>
              </tr>
              <tr v-for="data in documents" :key="data.document_id">
                <td>{{ data.lable || "--" }}</td>
                <td class="text-justify">
                  {{ data.comments ? data.comments : "--" }}
                </td>
                <td class="candidate-file-name">{{ data.name || "--" }}</td>
                <td>{{ data.updatedDate }}</td>
                <td>
                  <a
                    class="hand-icon"
                    v-if="data && data.link"
                    @click="preview(data)"
                  >
                    <i class="fas fa-eye ml-3"></i>
                  </a>
                </td>
              </tr>
              <tr v-if="documents.length === 0">
                <td colspan="6">
                  <h6 v-if="resumeDocument.length" class="text-center text-gray">
                    No Further Documents to Preview
                  </h6>
                  <h6 v-else class="text-center text-gray">
                    No Documents to Preview
                  </h6>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <PreviewModal
        :modalTitle="previewModal.modalTitle"
        :modalColor="previewModal.modalColor"
        :isShowPopup="previewModal.isShowPopup"
        :buttons="previewModal.buttons"
        :modalCallBack="modalCallBack"
        :isPreview="previewModal.isPreview"
        :iframe="iframe"
        :size="previewModal.size"
        :closeOnBackdrop="previewModal.closeOnBackdrop"
      />
    </div>
  </div>
</template>
<script>
import PreviewModal from "@/components/reusable/PreviewModal";
import pdf from "vue-pdf";
import { BASE_URL } from "@/service_urls";
import { mapGetters } from "vuex";
import { isObject, isEmptyObjectCheck } from "@/helpers/helper";
import moment from "moment";
import { getScope, Role, appendAccessToken } from "@/helpers/helper";

export default {
  props: {
    candidate: {
      type: Object,
      default: null,
    },
  },
  components: { PreviewModal },
  data() {
    return {
      iframe: {
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: false,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
      previewModal: {
        modalColor: "primary",
        modalTitle: "Document Preview",
        isShowPopup: false,
        buttons: ["OK"],
        isPreview: false,
        size: "lg",
        closeOnBackdrop: false,
      },
    };
  },
  computed: {
    ...mapGetters(["getUploadedDocument", "isJobSupplierFromAccessToken", "isUKMainAgency"]),
    agencyCVDocument() {
      return _(this.documents).filter(({ document_type_id }) => document_type_id === 20).value()
    },
    isCandidateInRecruitmentProcess() {
      return this.candidate?.currently_in_recruitment_process || false;
    },
    documents() {
      return (
        (this.getUploadedDocument &&
          this.getUploadedDocument.length &&
          this.getUploadedDocument
            .filter(({ document_type_id }) => ![1, 23,24,25, 27, 26,28, 29, 30].includes(document_type_id))
            .map(
              ({
                document_type_id,
                uuid_link_to_doc,
                document_name,
                document_type,
                created_on,
                document_ext,
                candidate_document_id,
                comments,
              }) => {
                return {
                  document_type_id: document_type_id,
                  link: uuid_link_to_doc,
                  lable: document_type.comments,
                  name: document_name,
                  updatedDate: moment(created_on).format("DD-MM-YYYY"),
                  document_ext: document_ext?.document_ext,
                  document_id: candidate_document_id,
                  comments: comments,
                };
              }
            )) ||
        []
      );
    },
    resumeDocument() {
      return (
        (this.getUploadedDocument.length &&
          this.getUploadedDocument
            .filter(({ document_type_id }) => document_type_id === 1)
            .map(
              ({
                document_type_id,
                uuid_link_to_doc,
                document_name,
                document_type,
                created_on,
                document_ext,
                candidate_document_id,
                comments,
              }) => {
                return {
                  link: uuid_link_to_doc,
                  lable: document_type.comments,
                  name: document_name,
                  updatedDate: moment(created_on).format("DD-MM-YYYY"),
                  document_ext: document_ext?.document_ext,
                  document_id: candidate_document_id,
                  comments: comments,
                };
              }
            )) ||
        []
      );
    },
  },
  methods: {
    showPreview(data) {
      const { link } = data;
      //const _baseUrl = BASE_URL;
      //const _base_domain = _baseUrl.substring(0, _baseUrl.indexOf("/api"));
      const _base_domain = "https://" + window.location.host;
      return appendAccessToken(`${_base_domain}${link}`); 
    },
    preview(data) {
      var _baseUrl = BASE_URL;
      data.link_to_document = this.showPreview(data);
      // var _base_domain = _baseUrl.substring(0, _baseUrl.indexOf("/api"));
      var _base_domain = "https://" + window.location.host;
      if (["PNG", "JPG", "JPEG", "GIF"].includes(data.document_ext)) {
        this.iframe.loaded = false;
        this.iframe.ispdf = false;
        this.iframe.isImg = true;
        this.iframe.src =  data.link_to_document;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (data.document_ext === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(data.link_to_document);
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (
        ["TXT", "EPS", "TIF", "TIFF", "RAW"].includes(data.document_ext)
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message: "The selected file type is not available for Preview.",
        });
        this.iframe.src = "";
        this.iframe.loaded = false;
        this.iframe.isImg = false;
        this.iframe.pdf.ispdf = false;
        this.previewModal.isPreview = false;
        this.previewModal.isShowPopup = false;
      } else {
        this.iframe.isImg = false;
        this.iframe.ispdf = false;
        this.iframe.loaded = true;
        this.iframe.src =
          "https://view.officeapps.live.com/op/embed.aspx?src=" +
          data.link_to_document
          "";
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      }
    },
    modalCallBack(action) {
      this.previewModal.isPreview = false;
      this.previewModal.isShowPopup = false;
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.preview {
  .iframeclass {
    width: 75%;
  }
  .modalPdfContent {
    height: 350px !important;
    overflow-y: scroll;
  }
  .wrapper {
    -webkit-overflow-scrolling: touch;
    -moz-overflow-scrolling: touch;
    -ms-overflow-scrolling: touch;
  }
  @media all and (max-width: 480px) {
    .iframeclass {
      width: auto;
    }
  }
}
.action-button {
  width: 14px;
  height: 14px;
  border: 0;
  background: transparent;
  padding: 0;
  outline: none;
}
.hand-icon {
  cursor: pointer;
}
</style>