<template>
  <div>
    <div class="mt-2 text-right">
      <button
        class="text-primary bg-white"
        style="border: none; outline: none; font-weight: 500"
        @click="expandAll()"
      >
        {{ isExpandAll ? "- Collapse All" : "+ Expand All" }}
      </button>
    </div>
    <div class="qualification-section mt-2">
      <QualificationInfo
        :profile="profile"
        :isMobile="isMobile"
        :isEditable="isEditable"
      />
    </div>
    <div
      v-if="!isCandidateSupplierFromAccessToken"
      class="qualification-section mt-2 pt-4"
    >
      <SalaryBenefitsInfo
        :profile="profile"
        :isMobile="isMobile"
        :isEditable="isEditable"
      />
    </div>
    <div
      v-if="!isCandidateSupplierFromAccessToken"
      class="qualification-section mt-2 pt-4"
    >
      <InternalInfo
        :profile="profile"
        :isMobile="isMobile"
        :isEditable="isEditable"
      />
    </div>
    <div
      v-if="['customer_admin', 'customer_recruiter'].includes(currentUserRole)"
      class="pre-screening-section mt-2 pt-4"
    >
      <PreScreening
        :profile="profile"
        :isMobile="isMobile"
        :isEditable="isEditable"
      />
    </div>
    <div
      v-if="!isCandidateSupplierFromAccessToken"
      class="qualification-section mt-2 pt-4"
    >
      <AdvertisingInfo
        :profile="profile"
        :isMobile="isMobile"
        :isEditable="isEditable"
      />
    </div>
  </div>
</template>
<script>
import moment from "moment";
import { Role, getScope } from "@/helpers/helper";
import { mapGetters, mapActions } from "vuex";
import QualificationInfo from "@/containers/JobsDetailedView/JobDetailSection/QualificationPreferencesInfo";
import SalaryBenefitsInfo from "@/containers/JobsDetailedView/JobDetailSection/SalaryBenefitsInfo";
import InternalInfo from "@/containers/JobsDetailedView/JobDetailSection/InternalInfo";
import AdvertisingInfo from "@/containers/JobsDetailedView/JobDetailSection/AdvertisingInfo";
import PreScreening from "@/containers/JobsDetailedView/JobDetailSection/PreScreening/PreScreening";
export default {
  props: {
    profile: {
      type: Object,
      default: () => {},
    },
    isEditable: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    QualificationInfo,
    SalaryBenefitsInfo,
    InternalInfo,
    AdvertisingInfo,
    PreScreening,
  },
  data() {
    return {
      isMobile: false,
      isExpandAll: false,
      isExpand: {
        qualification: false,
        salary: false,
        detailedDescription: false,
        internal: false,
        advertising: false,
        prescreening: false,
      },
      currentUserRole: getScope(),
    };
  },
  computed: {
    ...mapGetters(["isCandidateSupplierFromAccessToken"]),
  },
  mounted() {
    let isMobile = window.matchMedia(
      "only screen and (max-width: 760px)"
    ).matches;
    this.isMobile = isMobile;
  },
  methods: {
    expandAll() {
      this.isExpandAll = !this.isExpandAll;
      for (const [key, value] of Object.entries(this.isExpand)) {
        this.isExpand[key] = this.isExpandAll;
      }
    },
    checkExpandAll() {
      let temp = false;
      for (const [key, value] of Object.entries(this.isExpand)) {
        value ? (temp = true) : null;
      }
      temp ? null : (this.isExpandAll = false);
    },
  },
};
</script>
