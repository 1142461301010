<template>
  <div>
    <CModal
      title="Candidate OnBoard - Documents"
      centered:true
      color="primary"
      :show.sync="onboardModel.isShowPopup"
      v-if="onboardModel.isShowPopup"
      size="lg"
    >
     <CRow class="row mb-3">
        <div class="p-3" style="width:100%">
          <CTabs variant="tabs" :active-tab.sync="currentTab" @update:activeTab="onTabChange">
            <CTab :title="`Return Documents (${candidateDocument_offerLetter ? candidateDocument_offerLetter.length : 0})`">
              <div v-if="candidateDocument_offerLetter" class="multi-records">
                <div
                  class="top-border d-flex"
                  style="justify-content: space-between"
                   v-for="(data, index) in candidateDocument_offerLetter" :key="index" 
                 >
                  <div class="ml-2 mt-1 record" style="min-width:80%" >
                    <CRow 
                      class="d-flex"
                      style="justify-content: space-around; margin-left: 2px"
                    >
                      <div class="table col col-4">
                        <p class="head">Document Type</p>
                        <p class="data">{{ data.label }}</p>
                      </div>
                      <div class="table col col-4">
                        <p class="head">Document Name</p>
                        <p class="data">{{ data.name }}</p>
                      </div>
                      <div class="table col col-4">
                        <p class="head">Uploaded Date</p>
                        <p class="data">{{ data.updatedDate }}</p>
                      </div>
                      <!-- <div class="text-justify table col col-12">
                        <p class="head">Description</p>
                        <a class="hand-icon btn pr-3 p-0" @click="editInfo(data)">
                          <i class="fas fa-pencil-alt"></i>
                        </a>
                        {{ data.comments ? data.comments : "--" }}
                      </div> -->
                    </CRow>
                  </div>
                  <div
                    class="widget-content-actions d-flex flex-column justify-content-center"
                    style="margin-right: 5px;"
                   >
                 <button
                      type="button"
                      :disabled="disabledButton.includes(0)"
                      class="btn icon hand-icon mb-1"
                      v-if="data && data.link"
                      @click="fileDownload(data,0)"
                    >
                      <i class="icon fas fa-download"></i>
                    </button>

                    <button
                      type="button"
                      class="btn icon hand-icon mb-1"
                      v-if="data && data.link"
                      @click="preview(data)"
                    >
                      <i class="fas fa-eye"></i>
                    </button>
                  </div>
                </div>
                
              </div>
                <p v-else class="text-muted h5 text-center p-4">Not Yet Uploaded</p>
             
            </CTab>
            <CTab :title="`OnBoard(${candidateDocument ? candidateDocument.length : 0})`">
              <CCard class="p-2">
                 <CCardBody>
                    
                    <ValidationObserver v-slot="{ handleSubmit }">
                      <form @submit.prevent="handleSubmit(onSubmit)">
                        
                        <CRow class="row mb-3">
                         
                          <label class="col-lg-3 col-md-12"
                            >Document Type</label
                          >
                              <Select class="col-lg-6 col-md-6"
                      name="document_type"
                      :value="uploadData.document_type || `--Select--`"
                      @input="handleChangeSelectType"
                      :options="
                        options && options['document_type']
                          ? options['document_type']
                          : []
                      "
                      :taggable="false"
                      :multiple="false"
                      :clearable="false"
                    />
                        <!--  <div class="col-lg-4 col-md-6 col-sm-12">
                          <ValidationObserver ref="DocumentInfo" tag="form">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                             <TextInput
                  name="comments_dtype"
                  ref="comments_dtype"
                  :value="uploadData.comments_dtype"
                  @input="handleInput_offer"
                   :error="errors[0]"
                />
                </ValidationProvider>
                </ValidationObserver>
                
                          </div>-->
                          
                        </CRow>
                        <CRow class="row mb-3" v-if="addbtnshow">
                          <label class="col-lg-3 col-md-12"
                            >Add Document</label
                          >
                          <div class="col-lg-4 col-md-6 col-sm-12">
                             <CButton
                        color="primary"
                        class="px-2 f-12"
                        @click="adddocument"
                        
                      >
                        ADD
                      </CButton>
                          </div>
                          <!-- <div v-if="cropperModal.isShowPopup">
                            <CropperModal
                              :isShowPopup="cropperModal.isShowPopup"
                              :fileData="cropperModal.fileData"
                              modalColor="primary"
                              :buttonLabel="cropperModal.buttonLabel"
                              @modalCallBack="cropperModalCallBack"
                              :aspectRatio="2"
                            />
                          </div> -->
                        </CRow>
                        <CRow class="row mb-3" v-if="custombtn">
                          <label class="col-lg-3 col-md-12"
                            >Upload Document</label
                          >
                         <div class="col-lg-4 col-md-6 col-sm-12">
                           <ValidationObserver ref="DocumentInfo" tag="form">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                             <TextInput
                  name="comments_dtype"
                  ref="comments_dtype"
                  :value="uploadData.comments_dtype"
                  @input="handleComments"
                   :error="errors[0]"
                />
                </ValidationProvider>
                </ValidationObserver>
                          </div>
                          <div class="col-lg-4 col-md-6 col-sm-12">
                            <input
                              name="upload-document"
                              type="file"
                              ref="fileref"
                              :accept="arraytoComma(fileType)"
                              @change="selectedFile"
                            />
                          </div>
                          <div v-if="isImageSelected">
                            <uploadDocument
                              :documentType="uploadData.document_type"
                              :isProfilePic="false"
                              :fileData="fileData"
                              @closeModal="closeModal"
                              v-on="$listeners"
                              @upload="upload"
                            />
                          </div>
                          <!-- <div v-if="cropperModal.isShowPopup">
                            <CropperModal
                              :isShowPopup="cropperModal.isShowPopup"
                              :fileData="cropperModal.fileData"
                              modalColor="primary"
                              :buttonLabel="cropperModal.buttonLabel"
                              @modalCallBack="cropperModalCallBack"
                              :aspectRatio="2"
                            />
                          </div> -->
                        </CRow>
                      </form>
                    </ValidationObserver>
                  </CCardBody>
                   <CRow
                    class="ml-2 mr-2 d-flex align-items-center documentScrolling document-table"
                    v-if="candidateDocument"
                  >
                    <table class="table"  v-if="candidateDocument.length">
                      <thead>
                        <tr>
                          <th class="w-25">Document Type</th>
                          <th>Document Name</th>
                          <th>Uploaded Date</th>
                          <th>Actionable</th>
                          <th>Download</th>
                          <th>Preview</th>
                          <th>Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(data, index) in candidateDocument" :key="index" >
                          <td :class="{ 'w-25': true, [data.label]: true }">
                            {{ data.label }}
                          </td>
                          <td class="candidate-file-name">{{ data.name }}</td>
                          <td>{{ data.updatedDate }}</td>
                          <td>
                            <input type="checkbox"
                            :value="data.document_id"
                            :checked="data.actionable || false"
                            @click="handleCheck"
                            />
                          </td>
                          <td>
                            
                             <button
                      type="button"
                      :disabled="disabledButton.includes(index)"
                      class="btn icon hand-icon mb-1"
                      v-if="data && data.link"
                      @click="fileDownload(data, index)"
                    >
                      <i class="icon fas fa-download"></i>
                    </button>
                    </td>
                          <td>
                            <a   class="btn icon hand-icon mb-1" v-if="data && data.link"  @click="preview(data)">
                                <i class="icon fas fa-eye ml-3"></i>
                            </a>
                          </td>
                          <td>
                            <button 
                              class="btn icon hand-icon mb-1" v-if="data && data.link" @click="deleteDocuments(data)">
                              <i class="icon fas fa-trash ml-3"></i>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </CRow>
              </CCard>

             <!-- <div v-if="orgDocuments.length" class="multi-records">
                <div
                  class="top-border d-flex"
                  style="justify-content: space-between"
                  v-for="(data, index) in orgDocuments"
                  :key="index"
                 >
                  <div class="ml-2 mt-1 record" style="min-width:80%">
                    <CRow
                      class="d-flex"
                      style="justify-content: space-around; margin-left: 2px"
                    >
                    <label style="display: flex;align-items: center;">
                      <input
                        type="checkbox"
                        :value="data.document_id"
                       
                      />
                    </label>
                      <div class="table col col-4">
                        <p class="head">Document Type</p>
                        <p class="data">{{ data.comments }}</p>
                      </div>
                      <div class="table col col-4">
                        <p class="head">Document Name</p>
                        <p class="data">{{ data.name }}</p>
                      </div>
                     
                    </CRow>
                  </div>

                  <div
                    class="widget-content-actions d-flex flex-column justify-content-center"
                    style="margin-right: 5px;"
                   >
                    <button
                      type="button"
                      :disabled="disabledButton.includes(index)"
                      class="btn icon hand-icon mb-1"
                      v-if="data && data.link"
                      @click="fileDownload(data, index)"
                    >
                      <i class="icon fas fa-download"></i>
                    </button>

                    <button
                      type="button"
                      class="btn icon hand-icon mb-1"
                      v-if="data && data.link"
                      @click="preview(data)"
                    >
                      <i class="fas fa-eye"></i>
                    </button>

                    <button
                      class="btn icon hand-icon"
                      v-if="data && data.link"
                      @click="deleteDocuments(data)"
                    >
                      <i class="fas fa-trash"></i>
                    </button> 
                  </div>
                </div>
                
              </div>
                <p v-else class="text-muted h5 text-center p-4">Not Yet Uploaded</p> -->
            </CTab>
          </CTabs>
          </div>
        </CRow>
           <CRow>
           <CCol lg="12">
          <h6 class="border-top-primary pt-2"></h6>
          </CCol>
          <CCol lg="6">
            <CRow class="mb-3">
              <label class="col-lg-12 required">Alert Set For</label>
              <div class="col-lg-12">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <DatePicker
                    name="expiry_date"
                    :value="profile.expiry_date"
                    @input="handleDateChange"
                    :error="errors[0]"
                    :showFromToday="true"
                  />
                </ValidationProvider>
              </div>
            </CRow>
          </CCol>
          <CCol lg="6">
            <CRow class="mb-3">
              <label class="col-lg-12 required">Priority Level</label>
              <div class="col-lg-12">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <Select
                    name="priority_level"
                    @input="handleSelect"
                    :value="profile.priority_level"
                    :options="
                      options && options['priority_level']
                        ? options['priority_level']
                        : []
                    "
                    :taggable="false"
                    :multiple="false"
                    :clearable="false"
                    :error="errors[0]"
                  />
                </ValidationProvider>
              </div>
            </CRow>
          </CCol>
        </CRow>
        <CRow>
          <CCol lg="12">
            <!---------------------------------------------------------------- DocumentsModel -->
            <CRow class="mb-3" >
                <div class="col-lg-12 col-md-12 d-flex pb-2">
                <div>
                  <label class="text-nowrap pr-3 required">Comments</label>
                </div>
                <div class="w-100" v-if="options['comments_options'].length">
                  <Select
                    name="comments_options"
                    @input="handlecommentsforrecruiter"
                    :value="commentsData.comments_options"
                    :options="
                      options && options['comments_options']
                        ? options['comments_options']
                        : []
                    "
                    :taggable="false"
                    :multiple="false"
                    :clearable="false"
                  />
                </div>
              </div>
              <div class="col-lg-12">
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <TextInput
                    name="comments"
                    :value="profile.comments"
                    label="comments"
                    @input="handleInput"
                    :error="errors[0]"
                  ></TextInput>
                </ValidationProvider>
              </div>
            </CRow>
          </CCol>
        </CRow>
        <template #footer-wrapper>
        <footer class="modal-footer">
          <button @click="onCancelClicked" class="btn btn-secondary">
            Cancel
          </button>
          <button
            @click="onOnboardSubmit"
            class="btn btn-primary"
            
          >
            Submit To Candidate
          </button>
        </footer>
      </template>
        </CModal>

    <PreviewModal
      :modalTitle="confirmationModal.modalTitle"
      :modalColor="confirmationModal.modalColor"
      :isShowPopup="confirmationModal.isShowPopup"
      :buttons="confirmationModal.buttons"
      :modalCallBack="modalCallBack_preview"
      :isPreview="confirmationModal.isPreview"
      :iframe="iframe"
      :size="confirmationModal.size"
      :closeOnBackdrop="confirmationModal.closeOnBackdrop"
    />

    <Modal
      :modalTitle="deleteConfirmModal.modalTitle"
      :modalColor="deleteConfirmModal.modalColor"
      :modalContent="deleteConfirmModal.modalContent"
      :isShowPopup="deleteConfirmModal.isShowPopup"
      :buttons="deleteConfirmModal.buttons"
      :modalCallBack="deletemodalCallBack_document"
      :closeOnBackdrop="false"
    />
    <Modal 
        :modalTitle="onboardConfirmModal.modalTitle" 
        :modalColor="onboardConfirmModal.modalColor" 
        :modalContent="onboardConfirmModal.modalContent" 
        :isShowPopup="onboardConfirmModal.isShowPopup" 
        :buttons="onboardConfirmModal.buttons"
        :modalCallBack="onboardConfirmModalCallBack"
        :closeOnBackdrop="false"
     />
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";

import DatePicker from "@/components/reusable/Fields/DatePicker";
import UploadDocument from "@/containers/CandidateProfile/UploadDocument";
import Modal from "@/components/reusable/Modal";
import PreviewModal from "@/components/reusable/PreviewModal";
import Select from "@/components/reusable/Fields/Select";
import TextInput from "@/components/reusable/Fields/TextInput";
import pdf from "vue-pdf";
import moment from "moment";
import { getLocalOrgDetail, isObject, isEmptyObjectCheck  } from "@/helpers/helper";

export default {
  props: {
    onboardModel: {
      type: Object,
      default: () => {
        isShowPopup: false;
        data: null;
      },
    },
  },
  components: {
    UploadDocument,
    PreviewModal,
    Modal,
    Select,
    TextInput,
    DatePicker
  },
  data() {
    return {
      commentsData: {},
      comments_data:null,
       candidate_document:{},
       custombtn:false,
      documenttitle_id:null,
      addbtnshow:true,
      org_doc_ids:[],
      documenttitle_id:null,
      currentTab: 1,
      disabledButton: [],
      iframe: {
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: false,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
      profile:{},
      confirmationModal: {
        modalColor: "primary",
        modalTitle: "Document Preview",
        isShowPopup: false,
        buttons: ["Ok"],
        isPreview: false,
        size: "lg",
        closeOnBackdrop: false,
      },
      uploadData: {},
      payload: {},
      isFileError: false,
      isFileTpeValid: false,
      FileTypeError: null,
      isImageSelected: false,
      fileData: {},
      cropperModal: {
        isShowPopup: false,
        fileData: null,
        buttonLabel: "Apply",
      },
      candidate_document_id: null,
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "NOTIFICATION",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
      onboardConfirmModal:{
        modalColor :"primary",
        modalTitle :"Document Upload",
        modalContent :"",
        isShowPopup :false,
        buttons :["Cancel","Confirm"],
      },
      showbtn: true,
       onboard_action: {
        action_id: 8,
        directAction: null,
        filter_id: 29,
        label: "Onboard",
        reason_id: null,
        status: null,
       tab_id: 4,
      },
    };
  },
  computed: {
    ...mapGetters([
      "getOrgDetail",
      "documentType",
      "uploaded",
      "getUploadedDocument",
      "candidateProfile",
      "getOfferDetailsforCandidate",
      "getJobDocuments",
      "getAllDocumentByOrganisationId",
      "fetchOffer",
      "getOfferActionDetails",
      "documentTypeForOnboard",
      "getorgdocsid",
      "getJobDetailsById",
      "getUser",
      "getActionComments"
    ]),
    options() {
      return {
       /* document_type:
          this.documentType
            .filter(({ code }) => [23, 24].includes(code))
            .map((data) => data) || [],
        // 23 - Offer Letter, 24 - Employment Contract*/
        document_type: this.documentTypeExcPhotoCustomOrder || [],
          comments_options: this.comments_data || [],
       
        priority_level: [
          { code: 1, label: 1 },
          { code: 2, label: 2 },
          { code: 3, label: 3 },
          { code: 4, label: 4 },
        ],
      };
    },
     documentTypeExcPhotoCustomOrder() {
      return (
        (this.documentTypeForOnboard &&
          this.documentTypeForOnboard.length &&
          this.documentTypeForOnboard.sort((a, b) =>
            a.label === "Terms & Conditions" ? -1 : 1
          )) ||
        []
      );
    },
     candidateDocument_offerLetter() {
      return (
        this.getUploadedDocument &&
        this.getUploadedDocument?.length &&
        this.getUploadedDocument
         .filter((docs) => {
            return [28].includes(docs.document_type_id) 
          })
          .map(
            ({
              document_type_id,
              link_to_doc,
              document_name,
              document_type,
              created_on,
              document_ext,
              candidate_document_id,
              version_no,
            }) => {
              return {
                link: link_to_doc,
                label: document_type.comments,
                name: document_name,
                updatedDate: moment(created_on).format("DD-MM-YYYY"),
                document_ext:document_ext?.document_ext,
                document_id:candidate_document_id,
                version_no:version_no,
              };
            }
          )
      );
    },
   orgDocuments() {
      return (
        this.getAllDocumentByOrganisationId?.length &&
        this.getAllDocumentByOrganisationId
         .filter((docs) => {
            return ([25].includes(docs.document_type_id) )
          })
        .map(
          ({
            document_type_id,
            link_to_doc,
            document_name,
            document_type,
            created_on,
            document_ext,
            organisation_document_id,
            comments,
          }) => {
             return {
              document_type_id: document_type_id,
              link: link_to_doc,
              label: document_type.comments,
              name: document_name,
              updatedDate: moment(created_on).format("DD-MM-YYYY"),
              document_ext: document_ext?.document_ext,
              document_id: organisation_document_id,
              comments: comments,
            };
          }
        )
      );
    },
    candidateDocument() {
      return (
        this.getUploadedDocument?.length &&
        this.getUploadedDocument
          .filter((docs) => {
            return ([25].includes(docs.document_type_id) && [this.onboardModel.data?.job_id].includes(docs.job_id))
          })
          .map(
            ({
              document_type_id,
              link_to_doc,
              document_name,
              document_type,
              created_on,
              document_ext,
              candidate_document_id,
              comments,
              actionable,
              org_type
            }) => {
              return {
                link: link_to_doc,
                label: comments,
                name: document_name,
                updatedDate: moment(created_on).format("DD-MM-YYYY"),
                document_ext:document_ext?.document_ext,
                document_id:candidate_document_id,
                actionable:actionable,
                org_type:org_type
              };
            }
          )
      );
    },
    displayBtn(){
     if( this.getOfferDetailsforCandidate?.length == 0)
     {
       return true;
     }
     else
     { 
       return false;
     }
    },
    /*fileType() {
      if (this.documentType.length) {
        if (
          this.uploadData.document_type &&
          this.uploadData.document_type.code
        ) {
          let fileext = this.documentType
            .filter(({ code }) => code === this.uploadData.document_type.code)
            .map((d) => d.allowed_extensions)[0];
          fileext = fileext.map((d) => d.toLowerCase());
          return fileext;
        } else {
          let fileext = this.documentType
            .filter(({ code }) => code === 23)
            .map((d) => d.allowed_extensions)[0];
          fileext = fileext.map((d) => d.toLowerCase());
          return fileext;
        }
      }
      return [];
    },
  },*/
  fileType() {
      let fileext = this.documentTypeForOnboard;
      if (this.documentTypeForOnboard.length) {
        if (
          this.uploadData.document_type &&
          this.uploadData.document_type.code
        ) {
          let fileext = this.documentTypeForOnboard
            .filter(({ code }) => code === this.uploadData.document_type.code)
            .map((d) => d.allowed_extensions)[0];
          fileext = fileext.map((d) => d.toLowerCase());
          return fileext;
        } else {
          let fileext = this.documentTypeForOnboard
            .filter(({ code }) => code === 25)
            .map((d) => d.allowed_extensions)[0];
          fileext = fileext.map((d) => d.toLowerCase());
          return fileext;
        }
      }
      return [];
    },
  },
  mounted() {
    this.initFetchOptions();
   this.setDefaultAlertDate() ;
  this.fetchOfferActionDetails();
    let organisation_id  = this.getOrgId || this.getUser?.org_detail?.org_id || getLocalOrgDetail()?.organisation_id;
    this.fetchAllVersionDocumentByOrgId({ organisation_id: organisation_id })
       let currentpayload = {
        status_id: 58,
        sub_status_id: 60,
      };
      this.fetchCommentsfor_recruiter(currentpayload).then((res) => {
        let comments_data = this.getActionComments;
        let values = comments_data.map((val) => ({
          label: val.comments,
          code: val.comments_type_id,
        }));
        this.comments_data = values;
      });
   },
  methods: {
    ...mapActions([
      "getCandidateDetails",
      "fetchJobDocumentsByJobID",
      "downloadDocument",
      "initFetchOptions",
      "showToast",
      "uploadPhoto",
      "uploadResume",
      "deleteDocument",
      "deleteDocumentByCandidateId",
      "create_offer",
      "getOffersForCandidate",
      "fetchUploadedDocumentbyCandidateId",
      "uploadDocumentbycustomer",
      "updateOfferDocument_customer",
      "create_Onboard",
      "initUpdateCandidateStatus",
      "initFetchContactInfoOptions",
      "fetchSubStatusReasonAction",
      "markAsCompleted",
      "fetchOfferActionDetails",
      "fetchAllVersionDocumentByOrgId",
      "fetchDocumentIdBytitleId",
      "createCandidateRecord",
      "deleteCandidaterecord",
      "fetchCommentsfor_recruiter"
    ]),
     setDefaultAlertDate() {
      let alertDate = moment()
        .add(5, "d")
        .toDate();
      this.handleDateChange("expiry_date", alertDate);
      this.handleSelect("priority_level", { code: 1, label: 1 });
      return true;
    },
       handleDateChange(name, value) {
      Vue.set(this.profile, name, value);
      Vue.set(
        this.payload,
        name,
        value ? moment(value).format("YYYY-MM-DD 00:00:00") : null
      );
    },
    handleInput(name, value) {
      Vue.set(this.profile, name, value);
      Vue.set(this.payload, name, value);
    },
     handleSelect(name, value, action_data = null) {
      Vue.set(this.profile, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null,
      };

      if (["sub_status_id"].includes(name) && action_data) {
        const { action_id, label, filter_id, tab_id } = action_data;
        this.fetchJobCandidateReasonCode({
          tab_id,
          filter_id,
          action_id,
          sub_status_id: this.payload["sub_status_id"],
        }).then((res) => {
          if (this.getTabReasons.length === 1)
            this.handleSelect("reason_id", this.getTabReasons[0]);
        });
      }
    },
     handleCheck(name,value){
       const values = Array
      .from(document.querySelectorAll('input[type="checkbox"]'))
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.value);
      /*if(values.length > 1){
         this.showToast({
          class: "bg-danger text-white",
          message: "Select any one actionable document!",
        })
      }*/
    },
     handleInput_offer(name, value) {
    //  Vue.set(this.profile, name, value);
      Vue.set(this.uploadData, name, value);
      Vue.set(this.payload, name, value);
      
    },
     getdocuments() {
       return (
        (this.getAllDocumentByOrganisationId &&
          this.getAllDocumentByOrganisationId.length &&
          this.getAllDocumentByOrganisationId
           .filter(({organisation_document_id }) => this.org_doc_ids.includes(organisation_document_id))
            .map(
              ({
                document_type_id,
                link_to_doc,
                document_name,
                document_type,
                created_on,
                document_ext,
                organisation_document_id,
                comments,
                version_no,
              }) => {
                return {
                  link: link_to_doc,
                  lable: document_type.comments,
                  name: document_name,
                  updatedDate: moment(created_on).format("DD-MM-YYYY"),
                  document_ext: document_ext?.document_ext,
                  document_id: organisation_document_id,
                  comments: comments,
                  document_type_id: document_type_id,
                  version_no:version_no,
                };
              }
            )) ||
        []
      );
    },
    adddocument()
     {
        let orgdocs = this.getdocuments();
        if(orgdocs?.length !=0){
        const job_id = this.getJobDetailsById.job_id;
        const customer_uid = this.getUser.customer_id;
        this.payload = {
            ...this.payload,
            job_id : job_id,
            customer_uid : customer_uid,
            organisation_id : this.getOrgDetail.organisation_id,
            organisation_document_id:orgdocs?.length ? orgdocs[0].document_id : null,
            org_type:true,
            candidate_uid: this.onboardModel.data.candidate.candidate_uid
      }
        let temp = this.getUploadedDocument && this.getUploadedDocument?.length && this.getUploadedDocument
        .filter(({ document_type_id ,org_type , documenttitle_id}) => document_type_id === 25 && org_type === true && documenttitle_id === this.documenttitle_id)
       // .filter(({ org_type }) => org_type === true)

        if(temp.length > 0){
           for(let i=0;i<temp.length;i++){
              if(temp[i].org_type){
               if(temp[i].documenttitle_id == this.documenttitle_id){
                  this.showToast({
                  class: "bg-info text-white",
                  message: "Document Already Exist.",
                  });
              }
              else{
                this.createCandidateRecord(this.payload).then((res)=>{ 
                    this.afteruploaded();
                  });
                break;
              }
           }
        }
        }
        else
        {
          this.createCandidateRecord(this.payload).then((res)=>{ 
                    this.afteruploaded();
                  });
        }
        }
        else
        {
             this.showToast({
                  class: "bg-info text-white",
                  message: "Document Not Found.",
                  });
        }
      this.uploadData = {};
     },
     handleComments(name,value) {
        Vue.set(this.uploadData, name, value);
      this.payload = {
        ...this.payload,
        comments :value ? value : null,
      };
      
    },
    handlecommentsforrecruiter(name,value) {
     if (value) {
        Vue.set(this.commentsData, name, value.label);
        Vue.set(this.profile, "comments", value.label);
        Vue.set(this.payload, "comments", value.label);
      }
    },
     handleChangeSelectType(name, value) {
      Vue.set(this.uploadData, name, value);
      if(value)
      {
      if(value.label.toLowerCase() != "Custom Type Document".toLowerCase())
      {
        this.addbtnshow = true
        this.custombtn = false
      }
      else
      {
        this.addbtnshow = false
        this.custombtn = true
      }
       this.payload = {
        ...this.payload,
        //comments :value ? value.label : null,
        [name]: value ? value.id || value.code || value : null,
        documenttitle_id: value ? value.documenttitle_id || value.documenttitle_id : null
      };
      if(value) this.documenttitle_id = value.documenttitle_id ? value.documenttitle_id : null
      
      this.payload={
          ...this.payload,
          organisation_id : this.getOrgDetail.organisation_id,

        }
        this.org_doc_ids = []
        this.fetchDocumentIdBytitleId(this.payload).then((res) => {
          let docs =  this.getorgdocsid
          docs?.length &&
          docs
          .filter(({candidate_type_id}) => this.onboardModel.data.candidate.candidate_type_id === candidate_type_id)
          .map((res)=>{
            this.org_doc_ids.push(res.document_id);
          })
        })
      }
    },
    handleChangeSelect(name, value) {
      Vue.set(this.uploadData, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null,
      };
    },
    selectedFile(event) {
      this.isFileError = false;
      this.isFileTpeValid = false;
      const size = event.target.files[0].size;
      if (Math.round(size / (1024 * 1024)) <= 2) {
        const file = event.target.files[0];
        const fileExt = this.getExtension(file.name);
        const fileTypeString = this.fileType;
        if (fileTypeString.includes(fileExt)) {
          if (file.type.startsWith("image/")) {
            // this.cropperModal.fileData = file;
            // this.cropperModal.isShowPopup = true;
            this.isImageSelected = true;
            this.fileData = file;
          } else {
            this.handleFileUploads(file);
          }
        } else {
          this.isFileTpeValid = true;
          this.FileTypeError = this.arraytoComma(fileTypeString);
          this.$refs.fileref.value = "";
          this.showToaster();
        }
      } else {
        this.$refs.fileref.value = "";
        this.isFileError = true;
        this.showToaster();
      }
    },
    handleFileUploads(event) {
      this.payload = {
        ...this.payload,
        name: ["file"],
        value: event,
        isPhotoUpload: false,
        job_id: this.onboardModel.data?.job_id,
      };
      this.upload(this.payload);
    },
    cropperModalCallBack(action, data) {
      if (action === false) {
        this.cropperModal.isShowPopup = false;
        this.cropperModal.fileData = null;
        this.$refs.fileref.value = "";
      } else {
        let { fileBlob, fileName } = data;
        this.payload = {
          ...this.payload,
          isPhotoUpload: true,
          name: ["file"],
          value: fileBlob,
          document_name: fileName,
        };
        this.cropperModal.isShowPopup = false;
        this.cropperModal.fileData = null;
        // this.$emit("imgUpdated", true);
        this.upload(this.payload);
      }
    },
   
    upload(payload) {
      const document_type = this.payload.document_type || 27;
      const { customer_uid, candidate_uid, user_id } = this.onboardModel.data.candidate;
      if (payload && payload.name) {
        if (payload.isPhotoUpload) {
          this.uploadPhoto({
            customer_uid,
            candidate_uid,
            user_id,
            [payload.name]: payload.value,
            document_type,
            ["fileName"]: payload.document_name,
            job_id: this.onboardModel.data?.job_id,
             comments :this.uploadData.comments_dtype,
          }).then((res)=>{
            this.afteruploaded()
          });
        } else {
          this.uploadResume({
            customer_uid,
            candidate_uid,
            user_id,
            [payload.name]: payload.value,
            document_type,
            job_id: this.onboardModel.data?.job_id,
             comments :this.uploadData.comments_dtype,
            
          }).then((res)=>{
            this.afteruploaded()
          });
        }
      }
      //this.$refs.comments_dtype.value=null
    },
    afteruploaded() {
      this.$refs.fileref.value = "";
      this.uploadData = {};
      //this.fetchUploadedDocument();
      let candetails = {
        customer_uid: this.onboardModel.data.candidate.customer_uid,
        candidate_uid: this.onboardModel.data.candidate.candidate_uid,
      };
      this.fetchUploadedDocumentbyCandidateId(candetails);

      let job_id = this.onboardModel.data?.job_id || null;
      if (job_id) this.fetchJobDocumentsByJobID(job_id);
      let offerDetails = {
        job_id: job_id,
        candidate_uid: candetails.candidate_uid,
      };
      this.fileData1 = {};
      this.isImageSelected = false;
       this.$refs.comments_dtype.value=null
      this.$refs.DocumentInfo.reset();
      this.$refs.DocumentInfo.errors.clear;
    },
    fileDownload(data, index) {
      this.disabledButton.push(index);
      let { link, name: document_name } = data;
      let url = link.replace("/api/v1/", "");
      this.downloadDocument({ url, document_name }).finally(() => {
        this.disabledButton.shift();
      });
      return false;
    },
    onCancelClicked() {
      this.payload = {};
      this.onboardModel.isShowPopup = false;
    },
    onSubmitClicked() {
      if (this.getUploadedDocument.length != 0) {
        this.payload = {
          status: false,
          candidate_uid: this.onboardModel.data.candidate.candidate_uid,
          customer_uid: this.onboardModel.data.candidate.customer_uid,
          job_id: this.onboardModel.data?.job_id,
        };
      //  this.create_offer(this.payload);
      //  this.showbtn = false;
        this.showToast({
          class: "bg-success text-white",
          message: "Offer sent to Candidate!",
        });
      } else {
        this.showToast({
          class: "bg-danger text-white",
          message: "Select the files.",
        });
      }
      
    },
    onOnboardSubmit() { 
       const values = Array
      .from(document.querySelectorAll('input[type="checkbox"]'))
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.value);
      if(values.length == 0)
      {
         this.showToast({
          class: "bg-danger text-white",
          message: "Select any one Organisation document!",
        })
        return false
      }
      if( this.profile.comments == null)
      { 
        this.showToast({
          class: "bg-danger text-white",
          message: "Please provide comments!",
        })
        return false
      }
       this.onboardConfirmModal.modalContent = `Are you sure to submit the onboarding documents ?`
        this.onboardConfirmModal.isShowPopup = true
    },
     
      onboardConfirmModalCallBack(action){
       if(action == "Confirm"){
      const values = Array
      .from(document.querySelectorAll('input[type="checkbox"]'))
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.value);

    let job_id,offer_id;
    this.fetchOffer.map((res)=>{
      job_id = res.job_id;
      offer_id=res.offer_id;
    })
    let payload1={is_accepted:false,
    offer_id: offer_id,
    job_id:job_id,
    organisation_document_ids: values
    }
   this.create_Onboard(payload1);
   this.showbtn=false;
   this.changeStatusfromOfferToOnBoard()
       }
         this.onboardModel.isShowPopup = false
        this.onboardConfirmModal.isShowPopup = false;
        
    },
    changeStatusfromOfferToOnBoard()
    {
        let finalpayload = {
        candidate_uid: this.onboardModel.data?.candidate_uid,
        job_id: this.onboardModel.data?.job_id,
        payload: {
          ...this.payload,
           tab_id: 4,
          filter_id: 29,
          action_id: 8,
          sub_status_id: 69,
          current_status: this.onboardModel.data?.status_id,
          current_sub_status:this.onboardModel.data?.sub_status_id,
          reviewed:true,
        //  comments: "Approved For Onboarding",
          reason_id: 130,
        },
      };
        this.initUpdateCandidateStatus(finalpayload);
                let action_details = this.getOfferActionDetails;
                action_details.map((res)=>{
                    let candidate_uid = this.onboardModel.data.candidate.candidate_uid
                    let job_id = this.onboardModel.data?.job_id
                       if(res.job_id == job_id && res.candidate_uid == candidate_uid){ 
                        let action_detail_id  = res.action_detail_id;
                        let payload = {
                            action_detail_id: action_detail_id
                         };
                         
                        this.markAsCompleted(payload)
                       }
                });
                this.onboardModel.isShowPopup = false
        this.onboardConfirmModal.isShowPopup = false;
    },
    onTabChange(tabIndex) {
      // if (tabIndex == 0) {
      //   let job_id  = this.offerModel.data?.job_id
      //   console.log(job_id);
      //   this.fetchJobDocumentsByJobID(job_id)
      // }
    },
    preview(data) {
      if (
        data.document_ext === "PNG" ||
        data.document_ext === "JPG" ||
        data.document_ext === "JPEG" ||
        data.document_ext === "GIF"
      ) {
        this.iframe.loaded = false;
        this.iframe.ispdf = false;
        this.iframe.isImg = true;
        this.iframe.src = "https://" + window.location.host + data.link;
        //this.iframe.src = "http://localhost" + data.link;
        this.confirmationModal.isPreview = true;
        this.confirmationModal.isShowPopup = true;
      } else if (data.document_ext === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(
          "https://" + window.location.host + data.link
        );

        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.confirmationModal.isPreview = true;
        this.confirmationModal.isShowPopup = true;
      } else if (
        data.document_ext === "TXT" ||
        data.document_ext === "EPS" ||
        data.document_ext === "TIF" ||
        data.document_ext === "TIFF" ||
        data.document_ext === "RAW"
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message: "The selected file type is not available for Preview.",
        });
        this.iframe.src = "";
        this.iframe.loaded = false;
        this.iframe.isImg = false;
        this.iframe.pdf.ispdf = false;
        this.confirmationModal.isPreview = false;
        this.confirmationModal.isShowPopup = false;
      } else {
        this.iframe.isImg = false;
        this.iframe.ispdf = false;
        this.iframe.loaded = true;
        this.iframe.src =
          "https://view.officeapps.live.com/op/embed.aspx?src=https://" +
          window.location.host +
          data.link +
          "";
        this.confirmationModal.isPreview = true;
        this.confirmationModal.isShowPopup = true;
      }
    },
    modalCallBack_preview(action) {
      this.confirmationModal.isPreview = false;
      this.confirmationModal.isShowPopup = false;
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
    },
    closeModal() {
      this.$refs.fileref.value = "";
      this.uploadData = {};
      this.fileData = {};
      this.isImageSelected = false;
    },
    getExtension(filename) {
      let parts = filename.split(".");
      return parts[parts.length - 1];
    },
    arraytoComma(d) {
      return d?.map((v) => "." + v).join(", ");
    },
    showToaster() {
      setTimeout(() => {
        this.isFileError = false;
        this.isFileTpeValid = false;
      }, 5000);
    },
    deleteDocuments(data) {
      this.deleteConfirmModal.modalContent = `Do you want to delete this document - ${data.name} ?`;
      this.candidate_document_id = data.document_id;
      this.deleteConfirmModal.isShowPopup = true;

      this.candidate_document = data;
    },
   /* deletemodalCallBack_document(action) {
      if (action === "Confirm") {
        let candetails = {
          customer_uid: this.onboardModel.data.candidate.customer_uid,
          candidate_uid: this.onboardModel.data.candidate.candidate_uid,
          candidate_document_id: this.candidate_document_id,
        };

        this.deleteDocumentByCandidateId(candetails);
        candetails = {
          customer_uid: this.onboardModel.data.candidate.customer_uid,
          candidate_uid: this.onboardModel.data.candidate.candidate_uid,
        };
      }
      this.candidate_document_id = "";
      this.deleteConfirmModal.isShowPopup = false;
    },*/
    deletemodalCallBack_document(action) {
      if (action === "Confirm") {
        let candetails = {
          customer_uid: this.onboardModel.data.candidate.customer_uid,
          candidate_uid: this.onboardModel.data.candidate.candidate_uid,
          candidate_document_id: this.candidate_document_id,
        };
        if(!this.candidate_document.org_type){
        this.deleteDocumentByCandidateId(candetails);
        candetails = {
           customer_uid: this.onboardModel.data.candidate.customer_uid,
          candidate_uid: this.onboardModel.data.candidate.candidate_uid,
         
        };
      }
      else{
         let candetails = {
          customer_uid: this.onboardModel.data.candidate.customer_uid,
          candidate_uid: this.onboardModel.data.candidate.candidate_uid,
          document_id: this.candidate_document_id,
          job_id:this.onboardModel.data?.job_id
        };
        this.deleteCandidaterecord(candetails)
      }
      }
      
      this.candidate_document_id = "";
      this.deleteConfirmModal.isShowPopup = false;
    },
  },
  
  watch: {
     uploaded() {
      if (this.uploaded) {
        //this.$refs.comments_dtype.value = "";
        this.uploadData.comments_dtype = "";
        this.uploadData = {};
        //this.fetchUploadedDocument();
        if(this.onboardModel.data != null){
        let candetails = {
          customer_uid: this.onboardModel.data.candidate.customer_uid,
          candidate_uid: this.onboardModel.data.candidate.candidate_uid,
        };
        this.fetchUploadedDocumentbyCandidateId(candetails);
      }
        this.fileData1 = {};
        this.isImageSelected = false;
      }
    },
  }
};
</script>
<style lang="scss" scoped>
.cursor-default {
  cursor: default;
}
.ratio-1 {
  aspect-ratio: 1;
}
.hand-icon {
  cursor: pointer;
}

.action-button {
  width: 14px;
  height: 14px;
  border: 0;
  background: transparent;
  padding: 0;
  outline: none;
}
</style>
